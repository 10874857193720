import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CLoadingButton,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { Helmet } from 'react-helmet'

import * as cusUtil from 'src/viewsCommon/CusUtil'
import * as cusUrlUtil from 'src/viewsCommon/CusUrlUtil'
import CusResultModal from 'src/viewsCommon/CusResultModal'
import CenterRuleDetailViewModal from 'src/viewsCus/centerRule/CenterRuleDetailViewModal'

const Login_pwan = () => {
  const [btnState, setBtnState] = useState(false)
  const [isResultOpen, setIsResultOpen] = useState(false)
  const [resultTitle, setResultTitle] = useState('')
  const [resultBody, setResultBody] = useState('')

  const openResultModal = () => {
    setIsResultOpen(true)
  }

  const closeResultModal = () => {
    setIsResultOpen(false)
  }

  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  // 쿼리 매개변수에서 특정 매개변수의 값을 가져옴
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const urlParamPopup = queryParams.get('popup')
  const urlParamForward = queryParams.get('forward')
  const urlParamUserid = queryParams.get('u')
  const urlParamForwardDecode = decodeURIComponent(urlParamForward)

  // 최초실행
  useEffect(() => {
    // 쿠키 설정
    cusUtil.setCookie('isKiosk', 'n', 365)

    if (urlParamPopup == 'y') {
      setResultTitle('정보')
      setResultBody('로그인이 필요한 메뉴입니다.')
      openResultModal()
    }
    if (urlParamUserid) {
      setUsername(urlParamUserid)
    }
  }, [])

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin()
    }
  }

  const handlePasswordInit = (e) => {
    navigate('/passwordInit')
  }

  const handleLogin = () => {
    setBtnState(true)

    // 입력값 체크
    var saveDataCheckResult = ''
    if (username === '') {
      saveDataCheckResult = '아이디(휴대폰번호)를 입력해 주세요.'
    } else if (password === '') {
      saveDataCheckResult = '비밀번호를 입력해 주세요.'
    }
    if (saveDataCheckResult !== '') {
      setBtnState(false)
      setResultTitle('확인')
      setResultBody(saveDataCheckResult)
      openResultModal()
      return false
    }

    const credentials = {
      username: username,
      password: password,
    }

    cusUtil
      .axiosPost('/cube_dev_local/api/authenticate', credentials)
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          const jwttoken = responseData.jwttoken
          const jwttokenRe = responseData.jwttokenRe

          cusUtil.setCookie('jwttoken', jwttoken, 365)
          cusUtil.setCookie('jwttokenRe', jwttokenRe, 365)

          // 로그인 성공후 이동 페이지
          if (urlParamForward) {
            console.log('urlParamForward y', urlParamForward)
            navigate(urlParamForwardDecode)
          } else {
            console.log('urlParamForward n', urlParamForward)
            navigate('/dashboardCube')
          }
        } else {
          setResultTitle('확인')
          // setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)

          let resultMsg = ''
          if (responseData.resultCode == '3001' || responseData.resultCode == '3002') {
            resultMsg = '아이디 또는 비밀번호가 맞지않습니다.'
          } else {
            resultMsg = '로그인이 실패했습니다.'
          }
          setResultBody('[' + responseData.resultYne + '] ' + resultMsg)

          openResultModal()
        }

        // cusUtil.checkToken()
        setBtnState(false)
      })
      .catch((error) => {
        console.error('authenticate fail: ', error)
        setResultTitle('예외사항 발생')
        if (error.response && error.response.data && error.response.data.resultYne) {
          setResultBody('[' + error.response.data.resultYne + '] ' + error.response.data.resultMsg)
        } else {
          setResultBody('[' + error.code + '] ' + error.message)
        }
        openResultModal()

        setBtnState(false)
      })
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="{cusUrlUtil.getCidData()['REACT_APP_LOGO_IMAGE_TITLE']}"
        />
        <title>{cusUrlUtil.getCidData()['REACT_APP_LOGO_IMAGE_TITLE']}</title>
      </Helmet>

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h2>Login</h2>
                      <p className="text-medium-emphasis">
                        아이디 (휴대폰번호)와 비밀번호를 입력하세요.
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          placeholder="아이디 (휴대폰번호)"
                          autoComplete="username"
                          value={username}
                          onChange={handleUsernameChange}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="비밀번호"
                          autoComplete="current-password"
                          value={password}
                          onChange={handlePasswordChange}
                          onKeyPress={handleKeyPress}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs={6}>
                          <CButton color="link" className="px-0" onClick={handlePasswordInit}>
                            비밀번호 찾기/변경
                          </CButton>
                        </CCol>
                        <CCol xs={6} className="text-end">
                          <CLoadingButton
                            loading={btnState}
                            disabled={btnState}
                            size="sm"
                            color="primary"
                            className="px-4 text-white"
                            onClick={handleLogin}
                          >
                            로그인
                          </CLoadingButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard className="text-white bg-primary py-4">
                  <CCardBody className="text-left">
                    <div>
                      {/* <h2>Sign up</h2> */}
                      <p>
                        세계최초 스타트업이 운영하는 AI기반 전당포사나이 Pawnbox 서비스를 이용해
                        보세요!
                      </p>
                      <p>
                        &apos;편안함&apos;을 추구하는 전당포사나이를 방문해 주셔서 대단히
                        감사합니다.
                      </p>
                    </div>
                    {/* <div className="text-center">
                      <Link to="/commonMenu/MenuDashboardUser">
                        <CButton color="primary" size="sm" className="mt-1" active tabIndex={-1}>
                          전당포 사나이 센터 안내
                        </CButton>
                      </Link>
                    </div> */}
                    <div className="text-center">
                      <Link to="/register">
                        <CButton color="primary" size="sm" className="mt-1" active tabIndex={-1}>
                          초간단 회원가입
                        </CButton>
                      </Link>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
          <CRow className="justify-content-center mt-3">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="py-1">
                  <CCardBody className="text-left small ms-2">
                    <div className="small">
                      {/* <h2>Sign up</h2> */}
                      <p className="mb-2">
                        주식회사 전당포사나이대부
                        <br></br>대부업등록번호 : 2024-경기남양주-0009-대부
                        <br></br>주소: 경기도 남양주시 다산중앙로 105-18, 지앤지비즈타워 804호
                        <br></br>TEL: 02-6408-9888
                        <br></br>
                        <CenterRuleDetailViewModal ruleCd="01" ruleText="이용약관" isButton="n" />
                        <br></br>
                        <CenterRuleDetailViewModal
                          ruleCd="02"
                          ruleText="개인정보처리방침"
                          isButton="n"
                        />
                        <br></br>Copyright Loanit., Ltd. All right reserved.
                      </p>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <CusResultModal
        isOpen={isResultOpen}
        closeModal={closeResultModal}
        resultTitle={resultTitle}
        resultBody={resultBody}
      />
    </>
  )
}

export default Login_pwan
