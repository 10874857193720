import PropTypes from 'prop-types'
import React, { useRef, useState, useEffect } from 'react'
import Keyboard from 'react-simple-keyboard'
import hangul from 'hangul-js'
import 'react-simple-keyboard/build/css/index.css'
import { CFormInput, CModal, CModalBody } from '@coreui/react-pro'

const CustomKeyboard = ({
  isOpen,
  isPassword,
  closeKeboard,
  setInputKeyboard,
  initText,
  labelText,
  screenWidth,
  modalBorder,
  headerFontSize,
  bodyWithBtnHeight,
  footerBtnHeight,
}) => {
  const koreanLayout = {
    default: [
      '1 2 3 4 5 6 7 8 9 0',
      'ㅂ ㅈ ㄷ ㄱ ㅅ ㅛ ㅕ ㅑ ㅐ ㅔ',
      'ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ',
      '{shift} ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ {pre}',
      '{space} - , {dot} Clear {enterText}',
    ],
    shift: [
      '1 2 3 4 5 6 7 8 9 0',
      'ㅃ ㅉ ㄸ ㄲ ㅆ ㅛ ㅕ ㅑ ㅒ ㅖ',
      'ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ',
      '{shift} ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ {pre}',
      '{space} - , {dot} Clear {enterText}',
    ],
  }
  // console.log('initText:', initText)
  const [input, setInput] = useState(initText)
  const [layout, setLayout] = useState('default')
  const keyboard = useRef()

  // input 값이 변경될 때만 useEffect 실행
  useEffect(() => {
    // console.log('New input value:', input)
    setInputKeyboard(input)
  }, [input])

  useEffect(() => {
    setInput(initText)
  }, [initText])

  const onKeyPress = (button) => {
    // console.log('Button pressed =>', button)

    if (button === '{pre}') {
      const res = input.slice(0, -1)
      setInput(res)
    } else if (button === 'Clear') {
      setInput('')
    } else if (button === '{lock}') {
    } else if (button === '{shift}') {
      const newLayoutName = layout === 'default' ? 'shift' : 'default'
      setLayout(newLayoutName)
    } else if (button === '{enterNum}' || button === '{enterText}') {
      closeKeboard()
    } else if (button === '{dot}') {
      setInput((prev) => prev + '.')
    } else if (button === '{space}') {
      setInput((prev) => prev + ' ')
    } else {
      setInput((prev) => hangul.assemble(hangul.disassemble(prev + button)))
    }
  }

  const onChangeInput = (event) => {
    // console.log('onChangeInput')
    // const input = event.target.value
    // setInput(input)
    // keyboard.current.setInput(input)
  }

  const visibility = isOpen === true ? 'visible' : 'hidden'
  const inputType = isPassword === true ? 'password' : 'text'

  let btnHeightKiosk = false
  let marginTop = footerBtnHeight * 9.9
  let textboxPaddingX = footerBtnHeight * 0.05
  if (screenWidth === 1080) {
    marginTop = 1920 - 600 // 뒤에 숫자가 커질수록 위로 감, 스타일 변경전 margin
    // marginTop = 0 // fot test (pc 에서 스타일 확인용)
    btnHeightKiosk = true
    textboxPaddingX = footerBtnHeight * 0.03
  }

  return (
    <>
      <CModal
        className={screenWidth === 1080 ? 'kiosk form-input-kiosk' : 'kiosk'}
        backdrop="static"
        visible={isOpen}
        style={{
          width: `${screenWidth}px`,
          // minHeight: `${bodyWithBtnHeight * 0.5}px`,
          backgroundColor: 'rgb(244,244,244)',
          padding: '0',
          // marginTop: `${footerBtnHeight * 9.8}px`,
          marginTop: `${marginTop}px`,
          // marginLeft: `${(screenWidth - screenWidth * 0.9) / 1.8}px`,

          borderRadius: '0',
          // boxShadow: `0 0 0 ${modalBorder}px rgba(0, 0, 0, 0.4), 0 0 0 ${
          //   modalBorder + 1
          // }px rgba(255, 255, 255, 0.6)`,
        }}
      >
        <CModalBody>
          <div
            className="keyboardContainer"
            style={{
              visibility: `${visibility}`,
              padding: `${footerBtnHeight * 0.13}px`,
              paddingTop: `${footerBtnHeight * 0}px`,
            }}
          >
            {/* <input
            className="keyboardInput"
            value={input}
            placeholder="입력하려면 가상 키보드를 누르세요."
            onChange={onChangeInput}
            /> */}
            <div
              style={{
                paddingBottom: `${footerBtnHeight * 0.1}px`,
              }}
            >
              {labelText}
            </div>
            <div
              style={{
                paddingLeft: `${textboxPaddingX}px`,
                paddingRight: `${textboxPaddingX}px`,
                paddingTop: `${footerBtnHeight * 0}px`,
                paddingBottom: `${footerBtnHeight * 0.1}px`,
              }}
            >
              <CFormInput
                type={inputType}
                size="sm"
                className={screenWidth === 1080 ? 'form-input-kiosk p-15' : ''}
                value={input}
                onChange={onChangeInput}
              />
            </div>
            <Keyboard
              activeButtonClass="kiosk"
              keyboardRef={(r) => (keyboard.current = r)}
              layoutName={layout} // shift 처리시 필요
              onKeyPress={onKeyPress}
              layout={{ ...koreanLayout }}
              display={{
                '{enterText}': '완료',
                '{shift}': 'Shift',
                '{.}': '.',
                '{space}': 'Space',
                '{dot}': '.',
                '{pre}': 'Back',
              }}
              buttonTheme={[
                {
                  class: `hg-button ${btnHeightKiosk ? 'kiosk' : 'pc'}`,
                  buttons:
                    '1 2 3 4 5 6 7 8 9 0 ㅂ ㅈ ㄷ ㄱ ㅅ ㅛ ㅕ ㅑ ㅐ ㅔ ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ q w e r t y u i o p a s d f g h j k l z x c v b n m {space} - , {dot} Clear {enterText} {shift} {pre}',
                },
              ]}
            />
          </div>
        </CModalBody>
      </CModal>
    </>
  )
}

CustomKeyboard.propTypes = {
  isOpen: PropTypes.bool,
  isPassword: PropTypes.bool,
  closeKeboard: PropTypes.func,
  setInputKeyboard: PropTypes.func,
  initText: PropTypes.string,
  labelText: PropTypes.string,

  screenWidth: PropTypes.number,
  modalBorder: PropTypes.number,
  headerFontSize: PropTypes.number,
  bodyWithBtnHeight: PropTypes.number,
  footerBtnHeight: PropTypes.number,
}

export default CustomKeyboard
