import React, { useEffect, useState, useRef } from 'react'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNavLink,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CCallout,
  CFormSelect,
  CTableBody,
  CTableRow,
  CTableDataCell,
} from '@coreui/react-pro'
import * as cusUtil from 'src/viewsCommon/CusUtil'
import CusResultModal from 'src/viewsCommon/CusResultModal'

const U_UserPasswordInitModal = (param) => {
  const [isResultOpen, setIsResultOpen] = useState(false)
  const [inputType, setInputType] = useState('')
  const [resultTitle, setResultTitle] = useState('')
  const [resultBody, setResultBody] = useState('')

  const openResultModal = () => {
    // 입력 팝업 open
    setIsResultOpen(true)
  }

  const closeResultModal = () => {
    if (resultTitle == '성공' && visible == true) {
      // 결과 팝업 close
      setVisible(!visible)
    }
    // 입력 팝업 close
    setIsResultOpen(false)
  }

  const [visible, setVisible] = useState(false)
  const [headerDesc, setHeaderDesc] = useState('화면설명')
  const [data, setData] = useState({})

  const fetchData = async () => {
    console.log('uid: ', param.info.userUid)

    // 입력값 초기화
    setData({})
    setInputType('비밀번호 변경')
    setHeaderDesc(param.titleHead)

    setVisible(!visible)
  }

  const refs = {
    curPassword: useRef(null),
    password: useRef(null),
  }

  const saveData = () => {
    // console.log('refs.doorName: ', refs.doorName.current.value)
    setData((prevState) => ({
      ...prevState,
      curPassword: refs.curPassword.current.value,
      password: refs.password.current.value,
    }))

    const saveDataParam = {
      curPassword: refs.curPassword.current.value,
      password: refs.password.current.value,
    }
    console.log('saveDataParam: ', saveDataParam)
    cusUtil
      .axiosPostOrPut('/cube_dev_local/api/user/user/v02/user/password/my', saveDataParam)
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          setResultTitle('성공')
          setResultBody(inputType + '에 성공하였습니다.')
          openResultModal()
        } else {
          setResultTitle('에러')
          setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
          openResultModal()
        }
      })
      .catch((error) => {
        console.error('password save fail: ', error)
        setResultTitle('예외사항 발생')
        setResultBody('[' + error.code + '] ' + error.message)
        openResultModal()
      })
  }

  const CusButton = () => {
    return (
      <CButton color="success" size="sm" className="mx-0 text-white" onClick={fetchData}>
        {param.btnTitle}
      </CButton>
    )
  }

  return (
    <>
      <CusButton>{param.btnTitle}</CusButton>
      <CModal fullscreen backdrop="static" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
          <CModalTitle>{param.titleHead}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol xs={12}>
            <CCallout color="primary" className="p-10 mt-0 mb-1 border-radius-0">
              <div className="fs-7 text-medium-emphasis">
                현재 비밀번호와 변경할 비밀번호를 입력해 주세요.
              </div>
            </CCallout>

            <CCard className="mb-2">
              {/* <CCardHeader>
          </CCardHeader> */}
              <CCardBody className="bg-opacity-100 px-0 py-6 small">
                <CTable small bordered align="middle" className="mb-0 border-05">
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="w-30 px-2">현재 비밀번호</CTableDataCell>
                      <CTableDataCell className="px-2">
                        <CFormInput
                          type="password"
                          size="sm"
                          className="px-025r border-0"
                          defaultValue=""
                          ref={refs.curPassword}
                        />
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="px-2">변경할 비밀번호</CTableDataCell>
                      <CTableDataCell className="px-2">
                        <CFormInput
                          type="text"
                          size="sm"
                          className="px-025r border-0"
                          defaultValue=""
                          ref={refs.password}
                        />
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCardBody>

              {/* <CCardBody className="bg-light dark:bg-white dark:bg-opacity-10">
                <CRow className="mb-3">
                  <CFormLabel className="col-sm-2 col-form-label">현재 비밀번호</CFormLabel>
                  <div className="col-sm-10">
                    <CFormInput type="password" size="sm" defaultValue="" ref={refs.curPassword} />
                  </div>
                </CRow>
                <CRow className="mb-3">
                  <CFormLabel className="col-sm-2 col-form-label">변경할 비밀번호</CFormLabel>
                  <div className="col-sm-10">
                    <CFormInput type="text" size="sm" defaultValue="" ref={refs.password} />
                  </div>
                </CRow>
              </CCardBody> */}
            </CCard>
          </CCol>

          <CContainer>
            <CRow>
              <CCol align="center">
                <CButton
                  color="dark"
                  size="sm"
                  className="mx-1 text-white"
                  onClick={() => setVisible(false)}
                >
                  취소
                </CButton>
                <CButton color="primary" size="sm" className="mx-1 text-white" onClick={saveData}>
                  {inputType}
                </CButton>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
      </CModal>
      <CusResultModal
        isOpen={isResultOpen}
        closeModal={closeResultModal}
        resultTitle={resultTitle}
        resultBody={resultBody}
      />
    </>
  )
}

export default U_UserPasswordInitModal
