import React, { useEffect, useState, createRef, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNavLink,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CCallout,
  CSmartTable,
  CDropdownHeader,
  CDropdownItem,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CImage,
  CInputGroup,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'

import { cilSettings, cilAccountLogout, cilUserPlus } from '@coreui/icons'
import * as cusUtil from 'src/viewsCommon/CusUtil'
import * as cusUtil_passit from 'src/viewsCus/_company/common/CusUtil_passit'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import CusResultModal from 'src/viewsCommon/CusResultModal'
import CusProgressModal from 'src/viewsCommon/CusProgressModal'
import U_UserPasswordInitModal from 'src/viewsCus/user/U_UserPasswordInitModal'

const UserDetailModal_passit = (param) => {
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({})

  const [isProgressOpen, setIsProgressOpen] = useState(false)

  const openCusProgressModal = () => {
    setIsProgressOpen(true)
  }

  const closeProgressModal = () => {
    setIsProgressOpen(false)
  }

  const [visibleFile, setVisibleFile] = useState(false)
  const [dataFileLink, setDataFileLink] = useState(false)

  const fetchData = async (isVisible) => {
    const url = '/cube_dev_local/api/user/user/v02/user/my'
    console.log('detail url: ', url)

    cusUtil
      .axiosGet(url)
      .then((response) => {
        console.log(response)

        setData(response.data)
        if (isVisible) {
          setVisible(!visible)
        }
      })
      .catch((error) => {
        console.error('user detail fail: ', error)
      })
  }

  // 파일업로드
  const fileInputRef_01 = useRef(null)
  const fileInputRef_02 = useRef(null)
  const [files, setFiles] = useState({
    selectedFile_01: null,
    selectedFile_02: null,
  })
  const [imageUrl_01, setImageUrl_01] = useState()
  const [imageUrl_02, setImageUrl_02] = useState()

  const handleFileClick_01 = () => {
    fileInputRef_01.current.click()
  }
  const handleFileClick_02 = () => {
    fileInputRef_02.current.click()
  }
  const handleFileChange_01 = (event) => {
    const file = event.target.files[0]
    setFiles((prevFiles) => ({
      ...prevFiles,
      selectedFile_01: file,
    }))
    const reader = new FileReader()

    reader.onload = () => {
      setImageUrl_01(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const handleFileChange_02 = (event) => {
    const file = event.target.files[0]
    setFiles((prevFiles) => ({
      ...prevFiles,
      selectedFile_02: file,
    }))
    const reader = new FileReader()

    reader.onload = () => {
      setImageUrl_02(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  // 파일 들어올때 실행
  useEffect(() => {
    // console.log('files', files)
    if (files.selectedFile_01 || files.selectedFile_02) {
      uploadData()
    }
  }, [files])

  const uploadData = async () => {
    // console.log('uploadData')

    // 진행중
    openCusProgressModal()

    // 파일 있는경우 파일업로드
    let attachFileUid = ''
    Object.entries(files).forEach(([fileKey, fileValue]) => {
      // console.log('Key:', fileKey, 'Value:', fileValue)

      if (fileValue) {
        const url = '/cube_dev_local/api/file/guest/upload'
        console.log('upload url: ', url)

        const formData = new FormData()
        formData.append('file', fileValue)

        cusUtil
          .axiosUpload(url, formData)
          .then((response) => {
            closeProgressModal()
            console.log(response)
            const responseData = response.data

            if (responseData.resultYne == 'Y') {
              setResultTitle('확인')
              setResultBody('정상적으로 등록 되었습니다.')
              // openResultModal()

              // console.log('result:', fileKey, responseData.resultMsg)
              if (fileKey == 'selectedFile_01') {
                attachFileUid = responseData.resultMsg
              } else if (fileKey == 'selectedFile_02') {
                attachFileUid = responseData.resultMsg
              }

              saveData(attachFileUid)
            } else {
              setResultTitle('에러')
              setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
              openResultModal()
            }
          })
          .catch((error) => {
            closeProgressModal()
            console.error('file upload fail: ', error)
            setResultTitle('예외사항 발생')
            setResultBody('[' + error.code + '] ' + error.message)
            openResultModal()
          })
      }
    })
  }

  const saveData = (attachFileUid) => {
    const attachFileRequestList = []
    const attachFileRequest = {
      attachFileUid: attachFileUid,
      // targetType: '01',
    }
    attachFileRequestList.push(attachFileRequest)

    const saveDataParam = {
      // userid: refs.userid.current.value,
      // name: refs.name.current.value,
      attachFileAddRequestList: attachFileRequestList,
    }
    console.log('saveDataParam: ', saveDataParam)

    cusUtil
      .axiosPostOrPut('/cube_dev_local/api/user/user/v02/user/my', saveDataParam)
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          setResultTitle('성공')
          setResultBody('사진이 추가되였습니다.')
          openResultModal()
          fetchData(false)

          // 초기화
          setFiles(() => ({
            selectedFile_01: null,
            selectedFile_02: null,
          }))
          setImageUrl_01(null)
          setImageUrl_02(null)
        } else {
          setResultTitle('에러')
          setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
          openResultModal()
        }
      })
      .catch((error) => {
        console.error('register fail: ', error)
        setResultTitle('예외사항 발생')
        setResultBody('[' + error.code + '] ' + error.message)
        openResultModal()
      })
  }

  const deleteFileData = (attachFileUid) => {
    const attachFileRequestList = []
    const attachFileRequest = {
      attachFileUid: attachFileUid,
      // targetType: '01',
    }
    attachFileRequestList.push(attachFileRequest)

    const saveDataParam = {
      // userid: refs.userid.current.value,
      // name: refs.name.current.value,
      attachFileDelRequestList: attachFileRequestList,
    }
    console.log('saveDataParam: ', saveDataParam)

    cusUtil
      .axiosPostOrPut('/cube_dev_local/api/user/user/v02/user/my', saveDataParam)
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          setResultTitle('성공')
          setResultBody('사진이 삭제되였습니다.')
          openResultModal()
          fetchData(false)
        } else {
          setResultTitle('에러')
          setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
          openResultModal()
        }
      })
      .catch((error) => {
        console.error('delete fail: ', error)
        setResultTitle('예외사항 발생')
        setResultBody('[' + error.code + '] ' + error.message)
        openResultModal()
      })
  }

  const fetchFileData = async (param) => {
    // console.log('fetchFileData', param)
    setVisibleFile(true)
    setDataFileLink(`${param}`)
  }

  const TableOrderGoodsFileData = () => {
    const fileData = data
    // console.log('paramFileData ', fileData)
    // console.log('fileData 1 ', fileData.attachFileList[0])
    // console.log('fileData 2 ', fileData.attachFileList[1])
    return (
      <CCol
        xs={12}
        style={{
          width: '98%',
        }}
      >
        <CRow>
          <CCol xs={12} className="py-2">
            {(() => {
              if (fileData.attachFileList[0]) {
                return (
                  <>
                    <CImage
                      rounded
                      thumbnail
                      src={`/cube_dev_local${fileData.attachFileList[0].linkUrl.replace(
                        '/download',
                        '/download/thumb',
                      )}/${cusUtil.getCookie('jwttoken').replace('Bearer ', '')}`}
                      // width={200} height={200}
                      onClick={() =>
                        fetchFileData(
                          `/cube_dev_local${fileData.attachFileList[0].linkUrl}/${cusUtil
                            .getCookie('jwttoken')
                            .replace('Bearer ', '')}`,
                        )
                      }
                      style={{
                        cursor: 'pointer',
                      }}
                      type="button"
                    />
                  </>
                )
              } else {
                return <></>
              }
            })()}
          </CCol>
          <CCol xs={12} className="pb-2">
            {(() => {
              if (fileData.attachFileList[0]) {
                return (
                  <>
                    <CButton
                      color="dark"
                      size="sm"
                      className="ms-2 text-white"
                      onClick={() => deleteFileData(fileData.attachFileList[0].attachFileUid)}
                    >
                      사진삭제
                    </CButton>
                  </>
                )
              } else {
                return (
                  <>
                    {/* <CButton
                      color="primary"
                      size="sm"
                      className="ms-2 text-white"
                      // onClick={() => setVisibleFile(false)}
                    >
                      사진추가
                    </CButton> */}
                    <CInputGroup className="mb-4">
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          border: '1px solid gray',
                        }}
                      >
                        {imageUrl_01 && (
                          <img
                            src={imageUrl_01}
                            alt=""
                            style={{
                              width: '50px',
                              height: '50px',
                            }}
                          />
                        )}
                      </div>
                      <CButton
                        color="light"
                        size="sm"
                        onClick={handleFileClick_01}
                        className="ms-2"
                      >
                        사진추가
                      </CButton>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange_01}
                        ref={fileInputRef_01}
                      />
                    </CInputGroup>
                  </>
                )
              }
            })()}
          </CCol>
          <CCol xs={12} className="py-2">
            {(() => {
              if (fileData.attachFileList[1]) {
                return (
                  <>
                    <CImage
                      rounded
                      thumbnail
                      src={`/cube_dev_local${fileData.attachFileList[1].linkUrl.replace(
                        '/download',
                        '/download/thumb',
                      )}/${cusUtil.getCookie('jwttoken').replace('Bearer ', '')}`}
                      // width={200} height={200}
                      onClick={() =>
                        fetchFileData(
                          `/cube_dev_local${fileData.attachFileList[1].linkUrl}/${cusUtil
                            .getCookie('jwttoken')
                            .replace('Bearer ', '')}`,
                        )
                      }
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </>
                )
              } else {
                return <></>
              }
            })()}
          </CCol>
          <CCol xs={12} className="pb-2">
            {(() => {
              if (fileData.attachFileList[1]) {
                return (
                  <>
                    <CButton
                      color="dark"
                      size="sm"
                      className="ms-2 text-white"
                      onClick={() => deleteFileData(fileData.attachFileList[1].attachFileUid)}
                    >
                      사진삭제
                    </CButton>
                  </>
                )
              } else {
                return (
                  <>
                    {/* <CButton
                      color="primary"
                      size="sm"
                      className="ms-2 text-white"
                      // onClick={() => setVisibleFile(false)}
                    >
                      사진추가
                    </CButton> */}
                    <CInputGroup className="mb-4">
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          border: '1px solid gray',
                        }}
                      >
                        {imageUrl_02 && (
                          <img
                            src={imageUrl_02}
                            alt=""
                            style={{
                              width: '50px',
                              height: '50px',
                            }}
                          />
                        )}
                      </div>
                      <CButton
                        color="light"
                        size="sm"
                        onClick={handleFileClick_02}
                        className="ms-2"
                      >
                        사진추가
                      </CButton>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange_02}
                        ref={fileInputRef_02}
                      />
                    </CInputGroup>
                  </>
                )
              }
            })()}
          </CCol>
        </CRow>
      </CCol>
    )
  }

  const TableDetailFileData = () => {
    return (
      <CCol xs={12}>
        <CCardBody className="pt-0 pb-3">
          <Zoom>
            <img
              src={dataFileLink}
              alt="Zoomable"
              style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
            />
          </Zoom>
        </CCardBody>
      </CCol>
    )
  }

  const TableData = () => {
    const userid = `${data.userid}`
    const name = `${data.name}`

    return (
      <>
        <CCol xs={12}>
          {/* <CCallout color="primary" className="p-10 mt-0 mb-1 border-radius-0">
            <div className="fs-7 text-medium-emphasis">나의 상세 정보 입니다.</div>
          </CCallout> */}
          <CCard className="mb-2">
            <CCardBody className="bg-opacity-100 px-0 py-6 small">
              <CTable small bordered align="middle" className="mb-0 border-05">
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="w-30 px-2">휴대폰번호</CTableDataCell>
                    <CTableDataCell className="px-2">
                      {cusUtil.getPhoneNumberStr(userid)}
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="w-30 px-2">이름</CTableDataCell>
                    <CTableDataCell className="px-2">{name}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="px-2">정면사진</CTableDataCell>
                    <CTableDataCell className="px-2">
                      <TableOrderGoodsFileData></TableOrderGoodsFileData>
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="px-2">회원가입일</CTableDataCell>
                    <CTableDataCell className="px-2">{cusUtil.getDtStr(data.regDt)}</CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>

        <CModal
          fullscreen
          backdrop="static"
          visible={visibleFile}
          onClose={() => setVisibleFile(false)}
        >
          <CModalHeader>
            <CModalTitle>사진 상세정보</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <TableDetailFileData />

            <CContainer>
              <CRow>
                <CCol align="center">
                  <CButton
                    color="dark"
                    size="sm"
                    className="mx-1 text-white"
                    onClick={() => setVisibleFile(false)}
                  >
                    닫기
                  </CButton>
                </CCol>
              </CRow>
            </CContainer>
          </CModalBody>
        </CModal>
      </>
    )
  }

  const [isResultOpen, setIsResultOpen] = useState(false)
  const [resultTitle, setResultTitle] = useState('')
  const [resultBody, setResultBody] = useState('')

  const openResultModal = () => {
    setIsResultOpen(true)
  }

  const closeResultModal = () => {
    setIsResultOpen(false)
  }

  const CusDeleteConfirm = (param) => {
    const [visible, setVisible] = useState(false)

    const handleOkClick = () => {
      setVisible(false)
      deleteData(param)
    }

    return (
      <>
        <CButton
          color="danger"
          size="sm"
          className="mx-1 text-white"
          onClick={() => setVisible(!visible)}
        >
          회원탈퇴
        </CButton>
        <CModal backdrop="static" visible={visible} onClose={() => setVisible(false)}>
          <CModalHeader>
            <CModalTitle>확인</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div>{param.name}</div>
            <div className="my-2">탈퇴 하시겠습니까?</div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="dark"
              size="sm"
              className="mx-1 text-white"
              onClick={() => setVisible(false)}
            >
              취소
            </CButton>
            <CButton
              color="primary"
              size="sm"
              className="mx-1 text-white"
              onClick={() => handleOkClick()}
            >
              확인
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    )
  }

  const deleteData = async ({ uid }) => {
    const url = '/cube_dev_local/api/user/user/v02/user/my/'
    console.log('delete url: ', url)

    cusUtil
      .axiosDelete(url)
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          setResultTitle('확인')
          setResultBody('정상적으로 탈퇴 되었습니다.')
          openResultModal()
        } else {
          setResultTitle('에러')
          setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
          openResultModal()
        }
      })
      .catch((error) => {
        console.error('user delete fail: ', error)
        setResultTitle('예외사항 발생')
        setResultBody('[' + error.code + '] ' + error.message)
        openResultModal()
      })
  }

  return (
    <>
      <CButton
        // size="sm"
        color="danger"
        className="text-white border-radius-0"
        onClick={() => fetchData(true)}
        style={cusUtil_passit.styleBtnGray}
      >
        {param.title}
      </CButton>
      <CModal fullscreen backdrop="static" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
          <CModalTitle>상세정보</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <TableData />

          <CContainer>
            <CRow>
              <CCol align="center">
                <CusDeleteConfirm uid={data.userUid} />
                <U_UserPasswordInitModal
                  btnTitle="비밀번호 변경"
                  titleHead="비밀번호 변경"
                  info={data}
                />

                <CButton
                  color="dark"
                  size="sm"
                  className="mx-1 text-white"
                  onClick={() => setVisible(false)}
                >
                  닫기
                </CButton>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
      </CModal>

      <CusResultModal
        isOpen={isResultOpen}
        closeModal={closeResultModal}
        resultTitle={resultTitle}
        resultBody={resultBody}
      />
      <CusProgressModal isOpen={isProgressOpen} closeModal={closeProgressModal} />
    </>
  )
}

export default UserDetailModal_passit
