import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormCheck,
  CLoadingButton,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {
  cilLockLocked,
  cilUser,
  cilBirthdayCake,
  cilInfo,
  cilCheck,
  cilAddressBook,
} from '@coreui/icons'
import { Helmet } from 'react-helmet'

import * as cusUtil from 'src/viewsCommon/CusUtil'
import * as cusUrlUtil from 'src/viewsCommon/CusUrlUtil'
import CusResultModal from 'src/viewsCommon/CusResultModal'

const PasswordInit_pwan = (param) => {
  const [btnState, setBtnState] = useState(false)
  const [btnAuthNumState, setBtnAuthNumState] = useState(false)
  const [isResultOpen, setIsResultOpen] = useState(false)
  const [inputType, setInputType] = useState('')
  const [resultTitle, setResultTitle] = useState('')
  const [resultBody, setResultBody] = useState('')

  const openResultModal = () => {
    // 입력 팝업 open
    setIsResultOpen(true)
  }

  const closeResultModal = () => {
    console.log('dataActionType', dataActionType)
    if (resultTitle == '성공') {
      if (dataActionType == 'passwordInit') {
        // 로그인화면으로 이동
        navigate('/login')
      }
    }
    // 입력 팝업 close
    setIsResultOpen(false)
  }

  const [visible, setVisible] = useState(false)
  const [headerDesc, setHeaderDesc] = useState('화면설명')
  const [data, setData] = useState({})

  const [dataActionType, setDataActionType] = useState('authNum')
  const [dataSendTypeCdOption, setDataSendTypeCdOption] = useState('03')

  // 최초실행 (빈 배열을 전달하여 한 번만 실행)
  useEffect(() => {}, [])

  // 입력
  const navigate = useNavigate()

  const refs = {
    userid: useRef(null),
    authNum: useRef(null),
    password: useRef(null),
    passwordRe: useRef(null),
  }

  const saveData = () => {
    setBtnState(true)

    // console.log('refs.title: ', refs.title.current.value)
    setDataActionType('passwordInit')
    setData((prevState) => ({
      ...prevState,
      userid: refs.userid.current.value,
      authNum: refs.authNum.current.value,
      password: refs.password.current.value,
      passwordRe: refs.passwordRe.current.value,
    }))

    // 입력값 체크
    var saveDataCheckResult = ''
    if (refs.userid.current.value === '') {
      saveDataCheckResult = '휴대폰번호를 입력해 주세요.'
    } else if (refs.authNum.current.value === '') {
      saveDataCheckResult = '인증번호를 입력해 주세요.'
    } else if (refs.password.current.value === '') {
      saveDataCheckResult = '비밀번호를 입력해 주세요.'
    } else if (refs.passwordRe.current.value === '') {
      saveDataCheckResult = '비밀번호 확인을 입력해 주세요.'
    } else if (refs.password.current.value !== refs.passwordRe.current.value) {
      saveDataCheckResult = '입력한 비밀번호가 일치하지 않습니다.'
    }
    if (saveDataCheckResult !== '') {
      setBtnState(false)
      setResultTitle('확인')
      setResultBody(saveDataCheckResult)
      openResultModal()
      return false
    }

    const saveDataParam = {
      userid: refs.userid.current.value,
      authNum: refs.authNum.current.value,
      password: refs.password.current.value,
    }
    console.log('saveDataParam: ', saveDataParam)

    cusUtil
      .axiosPostOrPut(
        '/cube_dev_local/api/guest/user/v02/user/password/init',
        saveDataParam,
        param.uid,
      )
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          setResultTitle('성공')
          setResultBody('비밀번호 변경' + '에 성공하였습니다.')
          openResultModal()
        } else {
          setResultTitle('에러')
          setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
          openResultModal()
        }
        setBtnState(false)
      })
      .catch((error) => {
        console.error('password init fail: ', error)
        if (error.response && error.response.data && error.response.data.resultYne) {
          const responseData = error.response.data
          if (responseData.resultCode) {
            let resultMsg = ''
            if (responseData.resultCode == '1003') {
              resultMsg = '인증번호를 다시 확인해 주세요.'
            }
            setResultTitle('확인')
            setResultBody('[' + responseData.resultCode + '] ' + resultMsg)
          } else {
            setResultTitle('에러')
            setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)
          }
          openResultModal()
        } else {
          setResultTitle('예외사항 발생')
          setResultBody('[' + error.code + '] ' + error.message)
          openResultModal()
        }

        setBtnState(false)
      })
  }

  const handleCancel = () => {
    navigate('/login')
  }

  const handleDataAuthTypeOptionChange = (event) => {
    setDataSendTypeCdOption(event.target.value)
  }

  const handleAuthNum = () => {
    setBtnAuthNumState(true)

    setDataActionType('authNum')
    setData((prevState) => ({
      ...prevState,
      userid: refs.userid.current.value,
      sendTypeCd: dataSendTypeCdOption,
    }))

    // 입력값 체크
    var saveDataCheckResult = ''
    if (refs.userid.current.value === '') {
      saveDataCheckResult = '휴대폰번호를 입력해 주세요.'
    } else if (!cusUtil.getPhoneNumberCheck(refs.userid.current.value)) {
      saveDataCheckResult = '휴대폰번호를 형식에 맞게 입력해 주세요.'
    }
    if (saveDataCheckResult !== '') {
      setBtnAuthNumState(false)
      setResultTitle('확인')
      setResultBody(saveDataCheckResult)
      openResultModal()
      return false
    }

    const saveDataParam = {
      userid: refs.userid.current.value,
      sendTypeCd: dataSendTypeCdOption,
    }
    console.log('saveDataParam: ', saveDataParam)

    cusUtil
      .axiosPostOrPut('/cube_dev_local/api/userAuthNum/user/request', saveDataParam)
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          setResultTitle('확인')
          setResultBody('인증번호 발송' + '에 성공하였습니다.')
          openResultModal()
        } else {
          setResultTitle('확인')
          // setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)

          let resultMsg = ''
          if (responseData.resultCode == '1000') {
            resultMsg = '회원이 아니거나 아이디가 확인되지 않습니다.'
          } else {
            resultMsg = '인증번호 발송에 실패하였습니다.'
          }
          setResultBody('[' + responseData.resultYne + '] ' + resultMsg)

          openResultModal()
        }
        setBtnAuthNumState(false)
      })
      .catch((error) => {
        console.error('password authNum fail: ', error)
        setResultTitle('예외사항 발생')
        setResultBody('[' + error.code + '] ' + error.message)
        openResultModal()
        setBtnAuthNumState(false)
      })
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="{cusUrlUtil.getCidData()['REACT_APP_LOGO_IMAGE_TITLE']}"
        />
        <title>{cusUrlUtil.getCidData()['REACT_APP_LOGO_IMAGE_TITLE']}</title>
      </Helmet>

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard className="mx-0">
                <CCardBody className="p-4">
                  <CForm>
                    <h2>비밀번호 변경</h2>
                    <p className="text-medium-emphasis">
                      1. 휴대폰번호 입력후 인증번호 발송 버튼을 눌려주세요.
                    </p>
                    <CInputGroup className="mb-2">
                      <CInputGroupText>
                        <CIcon icon={cilCheck} />
                      </CInputGroupText>
                      <CFormInput placeholder="휴대폰번호 *" ref={refs.userid} size="sm" />
                    </CInputGroup>

                    <div
                      style={{
                        width: '100%',
                        paddingBottom: '10px',
                      }}
                    >
                      <CRow>
                        <CCol xs={8} className="ta-right pt-1 px-0">
                          <CFormCheck
                            inline
                            type="radio"
                            name="inlineAuthOptions"
                            id="inlineAuthKakao"
                            value="03"
                            label="카톡"
                            checked={dataSendTypeCdOption === '03'}
                            onChange={handleDataAuthTypeOptionChange}
                          />
                          <CFormCheck
                            inline
                            type="radio"
                            name="inlineAuthOptions"
                            id="inlineAuthSms"
                            value="01"
                            label="sms"
                            checked={dataSendTypeCdOption === '01'}
                            onChange={handleDataAuthTypeOptionChange}
                          />
                        </CCol>
                        <CCol xs={4} className="ta-right pl-0">
                          <CButton
                            color="primary"
                            size="sm"
                            disabled={btnAuthNumState}
                            onClick={() => handleAuthNum()}
                          >
                            인증번호 발송
                          </CButton>
                        </CCol>
                      </CRow>
                    </div>

                    <p className="text-medium-emphasis">
                      2. 수신된 인증번호를 입력하고 비밀번호를 변경 해주세요.
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput placeholder="인증번호 *" ref={refs.authNum} size="sm" />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        size="sm"
                        placeholder="비밀번호 * (숫자 6자리)"
                        ref={refs.password}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        size="sm"
                        placeholder="비밀번호 확인 * (숫자 6자리)"
                        ref={refs.passwordRe}
                      />
                    </CInputGroup>

                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <CRow>
                        <CCol xs={6}>
                          <CButton
                            color="secondary"
                            style={{
                              width: '100%',
                            }}
                            onClick={() => handleCancel()}
                          >
                            취소
                          </CButton>
                        </CCol>
                        <CCol xs={6}>
                          <CLoadingButton
                            loading={btnState}
                            disabled={btnState}
                            color="success"
                            style={{
                              width: '100%',
                            }}
                            onClick={() => saveData()}
                          >
                            비밀번호 변경
                          </CLoadingButton>
                        </CCol>
                      </CRow>
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
        <CusResultModal
          isOpen={isResultOpen}
          closeModal={closeResultModal}
          resultTitle={resultTitle}
          resultBody={resultBody}
        />
      </div>
    </>
  )
}

export default PasswordInit_pwan
