// import { Link, useNavigate } from 'react-router-dom'
// import axios from 'axios'
// const domainCidData = require('./domainCidData.json') // JSON 파일 import
import domainCidData from '../config/domainCidList.json'

// apiUrl 을 가져오는 함수
// *** proxyCube.js 파일 함수와 동일하게 작성됨 (수정시 2군데 다 변경해야 함)
export function getTargetUrl() {
  // 현재 도메인 가져오기
  const whostname = window.location.hostname
  const hostname = whostname.replace(/:\d+$/, '')

  if (hostname.startsWith('cubev02-')) {
    // prod, dev server
    let apiUrl = hostname.replace('padmin', 'papi')
    apiUrl = apiUrl.replace('dadmin', 'dapi')

    apiUrl = apiUrl.replace('puser', 'papi')
    apiUrl = apiUrl.replace('duser', 'dapi')

    apiUrl = apiUrl.replace('pki', 'papi')
    apiUrl = apiUrl.replace('dki', 'dapi')

    // console.log('proxyCube apiUrl', apiUrl)
    return 'https://' + apiUrl
  } else {
    // env.cid.json 에 설정된 server
    // - 특정 도메인의 prod 일때만 처리
    // - 일반적인 도메인의 개발/prod 서버는 위의 로직으로 모두 처리됨
    const cid = getCid()
    let cidData
    if (hostname.startsWith('localhost')) {
      cidData = require('../config/env.localhost.json')
    } else {
      cidData = require('../config/spec/env.' + cid + '.json')
    }

    const apiUrl = cidData['REACT_APP_API_URL']
    // console.log('proxyCube apiUrl', apiUrl)
    return apiUrl
  }
}

// 도메인 판별후 없는 경우, JSON 파일에서 도메인 데이터를 가져오기
// *** proxyCube.js 파일 함수와 동일하게 작성됨 (수정시 2군데 다 변경해야 함)
export function getCid() {
  try {
    // 현재 도메인 가져오기
    const whostname = window.location.hostname
    const hostname = whostname.replace(/:\d+$/, '')

    if (hostname.startsWith('cubev02-')) {
      let cid = hostname.replace('cubev02-', '')
      cid = cid.replace('.padmin.tobesmart.co.kr', '')
      cid = cid.replace('.puser.tobesmart.co.kr', '')
      cid = cid.replace('.pki.tobesmart.co.kr', '')
      cid = cid.replace('.dadmin.tobesmart.co.kr', '')
      cid = cid.replace('.duser.tobesmart.co.kr', '')
      cid = cid.replace('.dki.tobesmart.co.kr', '')

      return cid
    } else {
      // JSON에서 현재 도메인에 해당하는 값을 찾기
      const value = domainCidData[hostname]
      if (value) {
        return value
      } else {
        return domainCidData['default']
      }
    }
  } catch (error) {
    console.error('fetchDomainList', error)
    return null
  }
}

// cid 정보 가져오기
export function getCidData() {
  // 현재 도메인 가져오기
  const whostname = window.location.hostname
  const hostname = whostname.replace(/:\d+$/, '')

  // env.cid.json 에 설정된 server
  // - 특정 도메인의 prod 일때만 처리
  // - 일반적인 도메인의 개발/prod 서버는 위의 로직으로 모두 처리됨
  const cid = getCid()
  // console.log('getCidData', cid)
  let cidData
  if (hostname.startsWith('localhost')) {
    cidData = require('../config/env.localhost.json')
  } else {
    cidData = require('../config/spec/env.' + cid + '.json')
  }

  return cidData
}
