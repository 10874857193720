import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CLoadingButton,
  CImage,
  CFormCheck,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { Helmet } from 'react-helmet'

import * as cusUtil from 'src/viewsCommon/CusUtil'
import * as cusUrlUtil from 'src/viewsCommon/CusUrlUtil'
import * as cusUtil_passit from 'src/viewsCus/_company/common/CusUtil_passit'
import CusResultModal from 'src/viewsCommon/CusResultModal'
import CenterRuleDetailViewModal from 'src/viewsCus/centerRule/CenterRuleDetailViewModal'

const Login_passit = () => {
  const [isResultOpen, setIsResultOpen] = useState(false)
  const [resultTitle, setResultTitle] = useState('')
  const [resultBody, setResultBody] = useState('')

  const openResultModal = () => {
    setIsResultOpen(true)
  }

  const closeResultModal = () => {
    setIsResultOpen(false)
  }

  const [btnLoignState, setBtnLoignState] = useState(false)
  const [isAutoLoginChecked, setIsAutoLoginChecked] = useState(true)

  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  // 쿼리 매개변수에서 특정 매개변수의 값을 가져옴
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const urlParamPopup = queryParams.get('popup')
  const urlParamForward = queryParams.get('forward')
  const urlParamUserid = queryParams.get('u')
  const urlParamForwardDecode = decodeURIComponent(urlParamForward)

  // 최초실행
  useEffect(() => {
    // 쿠키 설정
    cusUtil.setCookie('isKiosk', 'n', 365)
    // console.log('autoLogin : ', cusUtil.getCookie('autoLogin'))
    if (
      cusUtil.getCookie('autoLogin') == 'y' &&
      cusUtil.getJwtToken() != '' &&
      (cusUtil.getJwtToken() == '' || cusUtil.getJwtTokenRe() != '')
    ) {
      navigate('/dashboardCube')
    }

    if (urlParamPopup == 'y') {
      setResultTitle('정보')
      setResultBody('로그인이 필요한 메뉴입니다.')
      openResultModal()
    }
    if (urlParamUserid) {
      setUsername(urlParamUserid)
    }
  }, [])

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin()
    }
  }

  const handlePasswordInit = (e) => {
    navigate('/passwordInit')
  }

  const handleRegister = (e) => {
    navigate('/register')
  }

  const handleAutoLoginCheckedChange = (event) => {
    setIsAutoLoginChecked(event.target.checked)
  }

  const handleLogin = () => {
    setBtnLoignState(true)

    // 입력값 체크
    var saveDataCheckResult = ''
    if (username === '') {
      saveDataCheckResult = '아이디(휴대폰번호)를 입력해 주세요.'
    } else if (password === '') {
      saveDataCheckResult = '비밀번호를 입력해 주세요.'
    }
    if (saveDataCheckResult !== '') {
      setBtnLoignState(false)
      setResultTitle('확인')
      setResultBody(saveDataCheckResult)
      openResultModal()
      return false
    }

    const credentials = {
      username: username,
      password: password,
    }

    cusUtil
      .axiosPost('/cube_dev_local/api/authenticate', credentials)
      .catch((error) => {
        console.error('authenticate fail: ', error)
        setResultTitle('예외사항 발생')
        setResultBody('[' + error.code + '] ' + error.message)
        openResultModal()

        setBtnLoignState(false)
      })
      .then((response) => {
        console.log(response)
        const responseData = response.data

        if (responseData.resultYne == 'Y') {
          const jwttoken = responseData.jwttoken
          const jwttokenRe = responseData.jwttokenRe

          cusUtil.setCookie('jwttoken', jwttoken, 365)
          cusUtil.setCookie('jwttokenRe', jwttokenRe, 365)

          // 자동로그인 체크 저장
          if (isAutoLoginChecked) {
            cusUtil.setCookie('autoLogin', 'y', 36500)
          } else {
            cusUtil.setCookie('autoLogin', 'n', 36500)
          }

          // 로그인 성공후 이동 페이지
          if (urlParamForward) {
            console.log('urlParamForward y', urlParamForward)

            const searchParams = new URLSearchParams(urlParamForward.split('?')[1])
            const centerDoorUid = searchParams.get('centerDoorUid')
            const centerUid = searchParams.get('centerUid')

            cusUtil.setCookie('dataCenterDoorUid', centerDoorUid, 365)
            cusUtil.setCookie('dataCenterUid', centerUid, 365)
            cusUtil.setCookie('dataCenterName', '최근지점', 365)

            navigate(urlParamForwardDecode)
          } else {
            console.log('urlParamForward n', urlParamForward)
            navigate('/dashboardCube')
          }
        } else {
          setResultTitle('확인')
          // setResultBody('[' + responseData.resultYne + '] ' + responseData.resultMsg)

          let resultMsg = ''
          if (responseData.resultCode == '3001' || responseData.resultCode == '3002') {
            resultMsg = '아이디 또는 비밀번호가 맞지않습니다.'
          } else {
            resultMsg = '로그인이 실패했습니다.'
          }
          setResultBody('[' + responseData.resultYne + '] ' + resultMsg)

          openResultModal()
        }

        // cusUtil.checkToken()
        setBtnLoignState(false)
      })
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="{cusUrlUtil.getCidData()['REACT_APP_LOGO_IMAGE_TITLE']}"
        />
        <title>{cusUrlUtil.getCidData()['REACT_APP_LOGO_IMAGE_TITLE']}</title>
      </Helmet>

      <div style={cusUtil_passit.styleDivValign}>
        <div style={cusUtil_passit.styleDivValignTop}>
          <CRow>
            <CCol>
              <CImage fluid src="/images/pas/ic_logo_white.png" className="pt-5" />
            </CCol>
          </CRow>
        </div>

        <div style={cusUtil_passit.styleDivValignMiddle}>
          <CRow className="pt-5">
            <CCol>
              <CForm>
                <CInputGroup className="mb-0">
                  <CFormInput
                    placeholder="휴대폰번호"
                    autoComplete="username"
                    value={username}
                    onChange={handleUsernameChange}
                    className="border-0 border-radius-0 border-bottom-1-opa-2"
                    style={cusUtil_passit.styleInput}
                  />
                </CInputGroup>
                <CInputGroup className="mb-2">
                  <CFormInput
                    type="password"
                    placeholder="비밀번호"
                    autoComplete="current-password"
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyPress={handleKeyPress}
                    className="border-0 border-radius-0 border-bottom-1-opa-2"
                    style={cusUtil_passit.styleInput}
                  />
                </CInputGroup>
                <CRow className="mb-2">
                  <CCol style={cusUtil_passit.styleFontNormalLarge5}>
                    <CFormCheck
                      id="autoLoginChecked"
                      label="자동로그인"
                      checked={isAutoLoginChecked}
                      style={cusUtil_passit.styleFontNormalLarge5}
                      onChange={handleAutoLoginCheckedChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className="d-grid">
                    <CLoadingButton
                      loading={btnLoignState}
                      // size="sm"
                      color="danger"
                      className="text-white border-radius-0"
                      onClick={handleLogin}
                      style={cusUtil_passit.styleBtnRed}
                    >
                      로그인
                    </CLoadingButton>
                  </CCol>
                </CRow>
              </CForm>
              <CRow>
                <CCol className="me-2 my-3">
                  <Link
                    to="/register"
                    className="me-2"
                    style={cusUtil_passit.styleFontNormalLarge5}
                    onClick={handleRegister}
                  >
                    회원가입
                  </Link>
                  <span style={cusUtil_passit.styleFontNormalLarge5}>|</span>
                  <Link
                    to="/passwordInit"
                    className="ms-2"
                    style={cusUtil_passit.styleFontNormalLarge5}
                    onClick={handlePasswordInit}
                  >
                    비밀번호변경
                  </Link>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>

        <div style={cusUtil_passit.styleDivValignBottom}>
          <CRow>
            <CCol>
              <div className="mb-4" style={cusUtil_passit.styleFontNormalSmall5}>
                <CenterRuleDetailViewModal ruleCd="01" ruleText="이용약관" isButton="n" />
                &nbsp; | &nbsp;
                <CenterRuleDetailViewModal ruleCd="02" ruleText="개인정보처리방침" isButton="n" />
                <br></br>Copyright Tobesmart., Ltd. All right reserved.
              </div>
            </CCol>
          </CRow>
        </div>
      </div>

      <CusResultModal
        isOpen={isResultOpen}
        closeModal={closeResultModal}
        resultTitle={resultTitle}
        resultBody={resultBody}
      />
    </>
  )
}

export default Login_passit
