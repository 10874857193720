import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CPagination,
  CPaginationItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CFormInput,
} from '@coreui/react-pro'
import DaumPostcode from 'react-daum-postcode'
import CustomKeyboard from './CustomKeyboard'

const DaumPostModal = ({
  isOpen,
  closeModal,
  screenWidth,
  modalBorder,
  headerFontSize,
  bodyWithBtnHeight,
  footerBtnHeight,
}) => {
  // 가상키보드
  const [selectedKeyboardInput, setSelectedKeyboardInput] = useState(null)
  const [initKeyboardText, setInitKeyboardText] = useState('')
  const [keyboardLabelText, setKeyboardLabelText] = useState('')
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)
  const [isKeyboardPassword, setIsKeyboardPassword] = useState(false)

  // 입력 필드를 선택할 때 호출되는 함수
  const handleInputSelect = (fieldName, labelName, isPassword) => {
    // console.log('handleInputSelect', fieldName)
    setIsKeyboardOpen(true)
    setKeyboardLabelText(labelName)
    setIsKeyboardPassword(isPassword)
    setSelectedKeyboardInput(fieldName)
  }
  const handleCloseKeboard = () => {
    setIsKeyboardOpen(false)
    setIsKeyboardPassword(false)
    setSelectedKeyboardInput(null)
  }
  const setInputKeyboard = (text) => {
    // console.log('setInputKeyboard가 클릭되었습니다.', text, selectedInput)
    // 선택한 입력 필드가 없으면 무시
    if (selectedKeyboardInput === null) return

    if (selectedKeyboardInput === 'addr') {
      refs.addr.current.value = text
      // setQuery(text)
    }
  }
  // const [query, setQuery] = useState('')

  const refs = {
    addr: useRef(null),
  }
  // useEffect(() => {
  //   setQuery('aa')
  // }, [query])

  // console.log('LiveDemo:', isOpen, closeModal)
  const complete = (data) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    console.log(data)
    console.log(fullAddress)
    console.log(data.zonecode)

    closeModal(fullAddress)
  }

  // 스타일 정의
  const postCodeStyle = {
    width: '100%',
    height: '400px',
    display: 'block',
  }

  return (
    <>
      {/* <CButton onClick={() => setVisible(!visible)}>Launch Result modal</CButton> */}
      <CModal backdrop="static" visible={isOpen} onClose={closeModal}>
        <CModalHeader>
          <CModalTitle>우편번호 찾기</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {/* <CFormInput
            type="text"
            size="sm"
            defaultValue=""
            ref={refs.addr}
            onClick={() => handleInputSelect('addr', '주소', false)}
          /> */}
          <DaumPostcode
            style={postCodeStyle}
            onComplete={complete}
            // defaultQuery={query}
          ></DaumPostcode>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="primary"
            size="sm"
            className="mx-1 text-white"
            onClick={() => closeModal(null)}
          >
            닫기
          </CButton>
        </CModalFooter>
      </CModal>
      <CustomKeyboard
        isOpen={isKeyboardOpen}
        isPassword={isKeyboardPassword}
        closeKeboard={handleCloseKeboard}
        setInputKeyboard={setInputKeyboard}
        initText={initKeyboardText}
        labelText={keyboardLabelText}
        screenWidth={screenWidth}
        modalBorder={modalBorder}
        headerFontSize={headerFontSize}
        bodyWithBtnHeight={bodyWithBtnHeight}
        footerBtnHeight={footerBtnHeight}
      />
    </>
  )
}

DaumPostModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  screenWidth: PropTypes.number,
  modalBorder: PropTypes.number,
  headerFontSize: PropTypes.number,
  bodyWithBtnHeight: PropTypes.number,
  footerBtnHeight: PropTypes.number,
}

export default DaumPostModal
